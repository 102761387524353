// extracted by mini-css-extract-plugin
export {};
    if(module.hot) {
      (function() {
        var localsJsonString = undefined;
        // 1725978209125
        var cssReload = require("../../../../../../../Platform/node_modules/.pnpm/mini-css-extract-plugin@2.9.0_webpack@5.92.1_@swc+core@1.6.7_webpack-cli@4.10.0_/node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  